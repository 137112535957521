import {Article} from './articles.model';

export interface ArticlesFilter {
  // Pagination
  pageSize?: number;
  selectedPage?: number;

  // Sorting
  sortBy?: string;
  descending?: boolean;

  // Population
  populate?: ('subjectAreas' | 'topics' | 'authorId')[];
  select?: (keyof Article)[];

  // Filter (New and safely in usage)
  q?: string; // Search string

  institution?: string;
  topics?: string[]; // Kategorien
  subjectAreas?: string[]; // Fachrichtungen
  federalStates?: string[]; // Bundesländer

  only?: string; // Filter for only specific articles

  onlyForInstitution?: string; // Unlike institution, this also includes articles that are not restricted to any institution
  onlyForFederalState?: string;

  curAuthor?: string;

  // For Home page -> registerDate must be in the future
  hasOpenRegistration?: boolean;

  // For Article Creation -> check whether an article has the same links
  links?: string[];
}

export function parseArticleQuery(filter: ArticlesFilter, params: any) {
  if (+params.pageSize) {
    filter.pageSize = +params.pageSize;
  }
  if (+params.selectedPage) {
    filter.selectedPage = +params.selectedPage;
  }
  if (params.sortBy) {
    filter.sortBy = params.sortBy;
  }
  if (params.descending) {
    filter.descending = params.descending === 'true';
  }
  if (params.q) {
    filter.q = params.q;
  }
  if (params.topics) {
    filter.topics = params.topics.split(',');
  }
  if (params.subjectAreas) {
    filter.subjectAreas = params.subjectAreas.split(',');
  }
  if (params.federalStates) {
    filter.federalStates = params.federalStates.split(',');
  }
  if (params.only !== undefined) { // can be empty string
    filter.only = params.only;
  }
}

export function writeArticleQuery(filter: ArticlesFilter): Record<string, string | string[] | number | boolean | undefined> {
  const {pageSize, selectedPage, sortBy, descending, q, topics, subjectAreas, federalStates, only} = filter;

  return {
    pageSize,
    selectedPage,
    sortBy,
    descending,
    q,
    topics: topics?.join(','),
    subjectAreas: subjectAreas?.join(','),
    federalStates: federalStates?.join(','),
    only,
  };

}
